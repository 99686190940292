<template>
  <div class="header">
    <div class="basic-wrap h120 d-flex item-center">
      <div class="fs36 flex1">WellCourse</div>
      <router-link :to="{ path: `/index` }" class="mr60" :class="{'current': $route.name == 'Index'}">Home</router-link>
      <router-link :to="{ path: `/products` }" class="mr60" :class="{'current': $route.name == 'Products'}">Products</router-link>
      <router-link :to="{ path: `/aboutUs` }" class="mr60" :class="{'current': $route.name == 'AboutUs'}">About us</router-link>
      <router-link :to="{ path: `/login` }" class="btn">Login</router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Header',
  data () {
    return {}
  },
  components: {
  },
  mounted () {
  },
  methods: {
  }
}
</script>

<style lang="less" scoped>
.header{
  height: 120px;
  background: #000;
  color: #fff;
  font-size: 24px;
  .basic-wrap{
    background: #000;
  }
  a.current,a:hover{
    color: #3894FF;
  }
  .btn{
    width: 113px;
    height: 50px;
    line-height: 45px;
    text-align: center;
    font-size: 22px;
    border-radius: 10px;
    border: #fff 1px solid;
  }
}
</style>
