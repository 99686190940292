<template>
  <div id="app">
    <Header />
    <keep-alive :include="include">
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"></router-view>
    <Footer />
  </div>
</template>

<script>
import Header from '@/layout/basic-header.vue'
import Footer from '@/layout/basic-footer.vue'

export default {
  name: 'BasicLayout',
  data () {
    return {
      include: []
    }
  },
  components: {
    Header,
    Footer
  },
  watch: {
    $route(to, from) {
      if (to.meta.keepAlive) {
        !this.include.includes(to.name) && this.include.push(to.name);
      }
      if (from.meta.keepAlive && to.meta.deepth < from.meta.deepth) {
        var index = this.include.indexOf(from.name);
        index !== -1 && this.include.splice(index, 1);
      }
    }
  },
  mounted () {
    // localStorage.getItem('token') && this.$store.commit('setInfo',{ email: localStorage.getItem('email') })
  },
  methods: {
  }
}
</script>
<style lang="less">
.basic-wrap{
  width: 1350px;
  margin: 0 auto;
}

</style>
